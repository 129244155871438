<template>
    <div>
        <v-data-table
            elevation="2"
            :headers="headers"
            :items="citas"
            disable-sort
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            item-key="id"
            :loading="loading"

            :options.sync="optionsTable"
            hide-default-footer
        >

            <template v-slot:[`item.obs`]="{ item }">
                {{item.observacionesIngreso}}
                <div v-if="item.conflicto.length>0">
                <div v-for="(conf, id) in item.conflicto" :key="id">
                    <v-icon small color="orange">mdi-alert</v-icon>{{conf.startDate}} {{conf.medico}}
                </div>
                </div>
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
                <v-icon v-if="item.horaAtencion" @click="muestra(item)">mdi-magnify</v-icon>
                <v-icon v-if="!item.horaAtencion" @click="atenderCita(item)">mdi-check</v-icon>
                <v-icon v-if="!item.horaEgreso" @click="egresar(item)">mdi-exit-run</v-icon>
            </template>

        </v-data-table>
      <dialog-egreso
          :dialogEgreso.sync="dialogEgreso"
          :cita.sync="citaEgresa"
          :carga.sync="carga"
      />
    </div>
</template>

<script>
import DialogEgreso from "@/components/consulta/DialogEgreso.vue";
export default {
    mounted(){
        this.citas=[]
        this.carga()
    },
  components:{
    DialogEgreso
  },
    props:{
        medico: Number,
        tipoCal:String,
        estaConsulta:Object,
        espNombre: String
    },
    data:()=>({
      loading:false,
      citas:[],
      headers:[
          {text:'Paciente', value:'nombre', width: '30%'},
          {text:'Llegada', value:'horaLlegada', align:'center', width: '5%'},
          {text:'Atención', value:'horaAtencion', align:'center', width: '5%'},
          {text:'Salida', value:'horaEgreso', align:'center', width: '5%'},
          {text:'Observaciones', value:'obs', align:'center'},
          {text:'', value:'acciones', align:'center', width: '10%'},
      ],
      optionsTable:{},
      dialogEgreso: false,
      citaEgresa:{}
    }),
    methods:{
        async carga(){
            this.loading = true
            try{
                const req = await this.$http({
                    method:"GET",
                    url:'/consulta/pacientesTurnos',
                    params:{id:this.medico}
                })
                this.loading = false
                this.citas = req.data
            }catch(err){
                this.loading = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        muestra(itm){
            this.$emit('update:estaConsulta', itm)
            this.$emit('consultaSeleccionada', itm.id)
        },
        async atenderCita(item){
            this.loading = true
            try{
                const internamiento = await this.$http({
                    method:"POST",
                    url:'/movimientos/create',
                    params:{
                        cip:item.cip,
                        servicio:6,
                        area:12,
                        procedencia:24973,
                        tipoInternamiento:2,
                        alta:0,
                        internado:1,
                        cama:'C.E. '+ this.espNombre,
                        motivo:'Consulta externa '+ this.espNombre,
                        observaciones:item.observacionesIngreso
                    }
                })
                await this.$http({
                    method:"POST",
                    url:'/consulta/atenderCita',
                    params:{
                        id:item.id,
                        internamiento:internamiento.data.root.id,
                        movimiento:internamiento.data.i.id
                    }
                })
                this.loading = false
                this.$emit('update:estaConsulta', item)
                this.$emit('consultaSeleccionada', item.id)
            }catch(err){
                this.loading = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async egresar(item){
          this.citaEgresa = item;
          this.dialogEgreso = true;
        },
    }
}
</script>

<style>

</style>