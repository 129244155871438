<template>
  <v-dialog
      v-model="dialog"
      max-width="500"
      persistent
  >
    <v-form
        onSubmit="return false;"
    >
      <v-card>
        <v-card-title
            class="headline green lighten-2"
            primary-title
        >
          <span class="white--text">Egresar paciente</span>
        </v-card-title>

        <v-card-text class="pt-5">
          <v-expand-transition>
            <v-alert text border="left" type="error" v-if="alertError">
              {{errorMsg}}
            </v-alert>
          </v-expand-transition>

          <v-expand-transition>
            <v-alert text border="left" type="success" v-if="alertPaciente">
              {{pacienteMsg}}
            </v-alert>
          </v-expand-transition>
          <v-row no-gutters>
            <v-col class="px-1" xs="12" sm="3" md="3" lg="3" xl="3" cols="12">
              <!-- ----------------Revisar-------------------- -->
              <p>Cita numero: </p>
            </v-col>
            <v-col class="px-1" xs="12" sm="9" md="9" lg="9" xl="9" cols="12">
              <p>{{cita.id}}</p>
            </v-col>

            <v-col class="px-1" xs="12" sm="3" md="3" lg="3" xl="3" cols="12">
              <p>Paciente</p>
            </v-col>
            <v-col class="px-1" xs="12" sm="9" md="9" lg="9" xl="9" cols="12">
              <p>{{cita.nombre}}</p>
            </v-col>
            <v-col class="px-1" cols="12">
              <v-textarea
                  v-model="observaciones.text"
                  label="Observaciones"
                  filled
                  rounded
                  dense
              ></v-textarea>

            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>

          <v-btn
              text
              color="error"
              @click="cierra"
          >
            Cerrar
          </v-btn>

          <v-btn
              color="success"
              @click="egresa"
          >
            Egresar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  components:{
  },
  props:{
    dialogEgreso:Boolean,
    cita:Object,
    carga:Function
  },
  data:()=>({
    observaciones:{
      text:null
    },
    alertError:false,
    alertPaciente: false
  }),
  methods:{
    async egresa(){
      try{
        await this.$http({
          method:"POST",
          url:'/consulta/altaConsulta',
          params:{
            id:this.cita.id,
            observacionesEgreso:this.observaciones
          }
        })
        if(this.cita.horaAtencion === ""){
          await this.$http({
            method:"POST",
            url:'/movimientos/altainternamiento',
            params:{
              tipoAlta: 6,
              internamiento:this.cita.internamiento,
              movimiento:this.cita.movimiento,
              observacionesAlta: this.observaciones
            }
          })
        }
        this.dialog=false
        this.carga()
      }catch(err){
        this.loading = false
        this.dialog=false
        this.$store.dispatch('security/revisaError',err)
      }
    },

    // Cierra el dialog (cancela)
    cierra(){
      this.dialog=false
    }
  },

  computed:{
    // Para el manejo del DIALOG
    dialog:{
      get(){
        return this.dialogEgreso
      },
      set(val){
        this.alertPaciente=false
        this.alertError=false

        this.pacienteMsg=''
        this.errorMsg=''


        this.$emit('update:dialogEgreso',val)
      }
    }
  }
}
</script>

<style>

</style>